<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Website Details</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-switch
            label="Display on Website"
            v-model="fields.display_on_website"
            inset
            :error="errors.hasOwnProperty('display_on_website')"
            :error-messages="errors['display_on_websites']"
          ></v-switch>

          <v-textarea
            label="Description"
            v-model="fields.front_end_description"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('front_end_description')"
            :error-messages="errors['front_end_description']"
          ></v-textarea>

          <v-text-field
            label="Date Available"
            v-model="fields.front_end_date_available"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('front_end_date_available')"
            :error-messages="errors['front_end_date_available']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        display_on_website: false,
        front_end_description: null,
        front_end_date_available: null,
      },
      statuses: [
        { name: "Breeding Dog", value: "breeding-dog" },
        { name: "Speyed/Neutered", value: "speyed" },
        { name: "Breeding Complete", value: "breeding-complete" },
        { name: "Puppy", value: "puppy" },
        { name: "External", value: "external" },
      ],

      locations: [
        { name: "LHL", value: "lhl" },
        { name: "FCH", value: "fch" },
        { name: "Breeder Colleague", value: "breeder-colleague" },
        { name: "Customer", value: "customer" },
        { name: "External", value: "external" },
      ],
      genders: [
        { name: "Female", value: "F" },
        { name: "Male", value: "M" },
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    coat_types() {
      return this.$store.state.lhl.dogs["coat_types"];
    },

    sizes() {
      return this.$store.state.lhl.dogs["sizes"];
    },

    colours() {
      return this.$store.state.lhl.dogs["colors"];
    },

    markings() {
      return this.$store.state.lhl.breeds["markings"];
    },
  },

  methods: {
    openForm: function (dog = null) {
      if (dog !== null) {
        this.dog = dog;

        this.fields.display_on_website = dog.display_on_website;
        this.fields.front_end_description = dog.front_end_description;
        this.fields.front_end_date_available = dog.front_end_date_available;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        fields: this.fields,
      };

      payload.fields.formName = "website";

      this.$store
        .dispatch("lhl/litters/saveBasic", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;

      this.fields.display_on_website = null;
      this.fields.front_end_description = null;
      this.fields.front_end_date_available = null;
    },
  },
};
</script>
