<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1"></div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.websiteForm.openForm(litter)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Basic Details</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Display on Website</th>
                  <td>{{ litter.display_on_website ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Description</th>
                  <td>{{ litter.front_end_description }}</td>
                </tr>

                <tr>
                  <th>Date Available</th>
                  <td>{{ litter.front_end_date_available }}</td>
                </tr>

                <tr>
                  <th>Image #1</th>
                  <td>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom v-if="!litter.image1">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.introImagesForm.openForm('image1')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card v-if="litter.image1">
                        <v-img
                          :src="litter.image1.asset_urls.url"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                          width="100"
                        >
                        </v-img>
                        <v-card-actions>
                          <v-btn
                            icon
                            color="red"
                            @click="openDelete(litter.image1, 'image1')"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="blue"
                            @click="
                              $refs.introImagesForm.openForm(
                                'image1',
                                litter.image1
                              )
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </td>
                </tr>

                <tr>
                  <th>Image #2</th>
                  <td>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom v-if="!litter.image2">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.introImagesForm.openForm('image2')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card v-if="litter.image2">
                        <v-img
                          :src="litter.image2.asset_urls.url"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                          width="100"
                        >
                        </v-img>
                        <v-card-actions>
                          <v-btn
                            icon
                            color="red"
                            @click="openDelete(litter.image2, 'image2')"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="blue"
                            @click="
                              $refs.introImagesForm.openForm(
                                'image2',
                                litter.image2
                              )
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </td>
                </tr>

                <tr>
                  <th>Image #3</th>
                  <td>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom v-if="!litter.image3">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.introImagesForm.openForm('image3')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card v-if="litter.image3">
                        <v-img
                          :src="litter.image3.asset_urls.url"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                          width="100"
                        >
                        </v-img>
                        <v-card-actions>
                          <v-btn
                            icon
                            color="red"
                            @click="openDelete(litter.image3, 'image3')"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="blue"
                            @click="
                              $refs.introImagesForm.openForm(
                                'image3',
                                litter.image3
                              )
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </td>
                </tr>

                <tr>
                  <th>Image #4</th>
                  <td>
                    <v-card outlined class="mb-6">
                      <v-card-title
                        class="d-flex justify-start align-center grey lighten-3"
                      >
                        <v-tooltip bottom v-if="!litter.image4">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              depressed
                              color="green lighten-4 green--text"
                              @click="$refs.introImagesForm.openForm('image4')"
                            >
                              <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Basic Details</span>
                        </v-tooltip>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card v-if="litter.image4">
                        <v-img
                          :src="litter.image4.asset_urls.url"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.5)"
                          width="100"
                        >
                        </v-img>
                        <v-card-actions>
                          <v-btn
                            icon
                            color="red"
                            @click="openDelete(litter.image4, 'image4')"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            icon
                            color="blue"
                            @click="
                              $refs.introImagesForm.openForm(
                                'image4',
                                litter.image4
                              )
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        scrollable
        v-model="deleteDialog.open"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">Delete Image</v-card-title>
          <v-card-text>Are you sure you want to delete this image?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteDialog.loading"
              @click="saveDelete"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <IntroImagesForm ref="introImagesForm" />
      <WebsiteForm ref="websiteForm" />
    </v-container>
  </div>
</template>

<script>
import WebsiteForm from "../components/WebsiteForm";
import IntroImagesForm from "../components/clp/IntroImagesForm";

export default {
  components: { WebsiteForm, IntroImagesForm },

  data() {
    return {
      deleteDialog: {
        open: false,
        loading: false,
        image: {},
        type: null,
      },
    };
  },

  methods: {
    openDelete(image, type) {
      this.deleteDialog.image = image;
      this.deleteDialog.type = type;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.open = false;
      this.deleteDialog.loading = false;
      this.deleteDialog.image = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("lhl/litters/deleteImage", {
          appId,
          litterId: this.$route.params.litterId,
          imageId: this.deleteDialog.image.id,
          type: this.deleteDialog.type,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },

  computed: {
    litter() {
      return this.$store.getters["lhl/litters/get"];
    },
  },
};
</script>
